// @ts-nocheck
import model from './model';

enum ElementId {
  PerksContainer = '#perksContainer',
  PerkText = '#perkText',
  PerkDivider = '#perkDivider',
}

export default model.createController(({ $w }) => {
  return {
    pageReady: async () => {},
    exports: {
      setPerks: (perks?: string[]) => {
        if (!perks) {
          return;
        }

        $w(ElementId.PerksContainer).onItemReady(($item, itemData, i) => {
          $item(ElementId.PerkText).text = itemData.text;
          if (i === perks.length - 1) {
            $item(ElementId.PerkDivider).collapse();
          }
        });

        $w(ElementId.PerksContainer).data = perks.map((perk, i) => ({
          _id: 'perk-' + i,
          text: perk,
        }));
      },
    },
  };
});
