import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { PricingPlansPublicAPI } from '@wix/pricing-plans-tpa-api';
import { getPlanPrice } from '@wix/pricing-plans-utils';
import { ViewerScriptFlowAPI, InitAppForPageFn, TFunction } from '@wix/yoshi-flow-editor';
import { getFreeTrialDaysLabel } from './utils/getFreeTrialDaysLabel';
import { getPeriodLabel } from './utils/getPeriodLabel';
import { getPlanDuration } from './utils/getPlanValidityCycle';

let translations: ViewerScriptFlowAPI['translations'];
let formatCurrency: ViewerScriptFlowAPI['formatCurrency'];

export const initAppForPage: InitAppForPageFn = async (_initParams, _apis, _namespaces, _platformServices, flowAPI) => {
  translations = flowAPI.translations;
  formatCurrency = flowAPI.formatCurrency;
};

export const exports: PricingPlansPublicAPI = {
  getTranslatedPricingDetails(plan: PublicPlan) {
    const t = translations.t as TFunction;
    const { value, currency } = getPlanPrice(plan);
    const formattedPrice = value && currency ? (formatCurrency({ value, currency }) as string) : '';
    return {
      price: formattedPrice,
      cycle: getPeriodLabel(plan.pricing?.subscription, t) ?? '',
      duration: getPlanDuration(plan, t),
      freeTrial: getFreeTrialDaysLabel(plan, t),
    };
  },
};
