export function encodeBase64Url<T>(input: T) {
  return toBase64Url(encodeBase64(input));
}

function toBase64Url(base64: string) {
  return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

function encodeBase64<T>(input: T) {
  return try2(btoa, encode, JSON.stringify(input));
}

function try2<Args extends any[], R>(f1: (...a1: Args) => R, f2: (...a2: Args) => R, ...args: Args): R {
  try {
    return f1(...args);
  } catch {
    return f2(...args);
  }
}

function encode(str: string): string {
  return btoa(encodeURIComponent(str).replace(/%[0-9A-F]{2}/g, percentToByte));
}

function percentToByte(p: string) {
  return String.fromCharCode(parseInt(p.slice(1), 16));
}
