import type { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import type { IWixAPI } from '@wix/yoshi-flow-editor';

export interface TranslatedPricingDetails {
  price: string;
  cycle: string;
  duration: string;
  freeTrial: string;
}

export interface PricingPlansPublicAPI {
  getTranslatedPricingDetails(plan: PublicPlan): TranslatedPricingDetails;
}

/**
 * Retrives Pricing Plans public API using wixCodeApi.site.getPublicAPI.
 *
 * May throw if Pricing Plans is not installed in the site.
 */
export function getPricingPlansPublicAPI(wixCodeApi: IWixAPI): Promise<PricingPlansPublicAPI> {
  return wixCodeApi.site.getPublicAPI('1522827f-c56c-a5c9-2ac9-00f9e6ae12d3');
}
