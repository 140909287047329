import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { ElementRole } from '../../constants/elements';
import { SinglePlanInteractions } from '../../types/SinglePlanFedops';
import { toError } from '../../utils/errors';
import model from './model';

enum StateId {
  // TODO: Fix typo in widget editor
  Plan = 'defualt',
  Spinner = 'loading',
  Empty = 'empty',
}

export default model.createController(({ $w, $bind, $widget, flowAPI }) => {
  const { planId } = $widget.props;

  const fetchPlan = async (): Promise<PublicPlan | null> => {
    if (!planId) {
      return null;
    }
    // TODO: Use warmup data
    const response = await flowAPI.httpClient.request(listPublicPlans({ planIds: [planId] }));
    return response.data.plans?.[0] ?? null;
  };

  const setPlan = async () => {
    if (!flowAPI.environment.isEditor && !flowAPI.environment.isSSR) {
      showSpinner();
    }

    try {
      const plan = await fetchPlan();
      if (!plan && flowAPI.environment.isEditor) {
        showLoadedState();
      } else if (!plan) {
        showEmptyState();
      } else {
        await $w(`#${ElementRole.PlanWidget}`).setPlan(plan);
        showLoadedState();
      }
      flowAPI.fedops.interactionStarted(SinglePlanInteractions.SetPlanData);
    } catch (e) {
      flowAPI.errorMonitor.captureException(toError(e));
      showEmptyState();
    }
  };

  const showSpinner = () => {
    return $w(`#${ElementRole.MultiStateBox}`).changeState(StateId.Spinner);
  };

  const showEmptyState = () => {
    return $w(`#${ElementRole.MultiStateBox}`).changeState(StateId.Empty);
  };

  const showLoadedState = () => {
    return $w(`#${ElementRole.MultiStateBox}`).changeState(StateId.Plan);
  };

  return {
    pageReady: () => {
      flowAPI.fedops.interactionStarted(SinglePlanInteractions.SetPlanData);
      return setPlan();
    },
    exports: {},
  };
});
